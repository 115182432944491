import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout/layout";

import SEO from "../../components/seo-mspi";

const laughteryoga = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="SWA Laughter Yoga Wellness Club"
          description="SWA laughter yoga to bring more laughter and joy for our members through the daily practice of Laughter Yoga to get physical, mental and emotional benefits of laughter."
          image="https://res.cloudinary.com/dkm14ge3g/image/upload/v1638592479/Logo/frjljjfxl0xzxxmn3wal.webp"
          imageAlt="SWA Laughter Yoga Wellness Club"
        />
        <section className="max-w-4xl px-2 mx-auto sm:w-4/5">
          <div className="relative py-16 overflow-hidden bg-white">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div
                className="relative h-full mx-auto text-lg max-w-prose"
                aria-hidden="true"
              >
                <svg
                  className="absolute transform translate-x-32 top-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                  />
                </svg>
                <svg
                  className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                  />
                </svg>
                <svg
                  className="absolute transform translate-x-32 bottom-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                  />
                </svg>
              </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto text-lg max-w-prose">
                <h1>
                  <span className="block text-base font-semibold tracking-wide text-center uppercase text-swa-2">
                    Introducing
                  </span>
                  <span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-swa-1 sm:text-4xl">
                    SWA Laughter Yoga Wellness Club
                  </span>
                </h1>
                <div className="my-5 border border-swa-4 aspect-w-16 aspect-h-9">
                  <iframe
                    src={`https://www.youtube.com/embed/0pG4OIPfk0k`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                    className="w-full p-2"
                  />
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  Laughter Yoga Health Craze Sweeping the World. Laughter Yoga
                  is not a comedy. It is an exercise program for Health and
                  Wellbeing. The yoga part of Laughter is the combination of
                  Laughter Exercises with yoga breathing techniques ( Pranayama
                  ). It was founded by a medical doctor Dr Madan Kataria in
                  1995. There are more than 20,000 free social Laughter Clubs in
                  110 countries.
                  <br />
                  <br />
                  Laughter Yoga is also being practised in Senior Centres &amp;
                  Homes, Aged Care Facilities, Schools, Colleges, Companies,
                  Factories, Physically and Mentally Challenged, Police, Prisons
                  and Voluntary Welfare Organisation (VWO).
                </p>
              </div>
              <div className="mx-auto mt-6 text-xl leading-8 prose prose-lg text-gray-500 prose-indigo">
                <h3>SWA members</h3>
                <p>
                  SWA laughter yoga to bring more laughter and joy for our
                  members through the daily practice of Laughter Yoga to get
                  physical, mental and emotional benefits of laughter. Sessions
                  via Zoom on 1st and 3rd Wednesday of each month from 8.30 p.m.
                  to 9.00 p.m. It was conducted by certified laughter yoga
                  leaders, Wendy Khon, Angela Wong and Fang Fang, with Sara Mei
                  Woo as an advisor.
                </p>
                <h3>Ren Ci Community Hospital</h3>
                <p>
                  It is a joint project with SWA Laugher Yoga Wellness club and
                  Laughter Yoga Singapore Wellness and Training centre to
                  provide physical, mental and emotional benefits of laughter to
                  the residents in the community hospital and senior care
                  centres. Sessions via Zoom are conducted once a week. Leaders
                  are Angela Wong, Fang Fang and Faith Ong with Sara Mei Woo as
                  an advisor.
                </p>
                <h3>Sree Narayana Mission (SNM) Singapore</h3>
                <p>
                  The sessions are conducted once a week for the seniors at
                  Senior Activity Centre. Leaders are Angela Wong, Fang Fang and
                  Faith Ong with Sara Mei Woo as an advisor
                </p>
              </div>
              <div className="px-4 py-8 mx-auto my-12 border border-gray-300 rounded-lg max-w-7xl sm:px-6">
                <h2 className="text-2xl font-bold text-gray-900 sm:text-4xl">
                  <span className="block">
                    If you are keen to participate or organise Laughter Yoga
                    sessions for your organisation ...
                    <span className=" text-swa-1">let's discuss.</span>
                  </span>
                </h2>
                <div className="flex mt-8">
                  <div className="inline-flex rounded-md shadow">
                    <Link
                      to="/forms/laughteryoga"
                      className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                    >
                      Contact us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default laughteryoga;
